import api from '@src/api';
import { TestSuiteRun } from '../types';
import { transformAPIGetTestRunsResponse } from '@src/modules/test-runs/api/transformers';

export const testRunsAPI = api
	.enhanceEndpoints({ addTagTypes: ['TestRuns'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			// [getTestRuns]
			getTestRuns: builder.query<TestSuiteRun[], void>({
				query: () => ({
					url: `test-runs`,
				}),
				providesTags: ['TestRuns'],
				transformResponse: transformAPIGetTestRunsResponse,
			}),
			unhideReport: builder.mutation<boolean, { id: string }>({
				query: ({ id }) => ({
					url: `test-runs/${id}`,
					method: 'PATCH',
					body: { isHidden: false },
				}),
				invalidatesTags: ['TestRuns'],
			}),
			// [getTestRunReportURL]
			getTestRunReportURL: builder.query<
				{ reportURL: string; altToken: string },
				{ id: string }
			>({
				query: ({ id }) => ({
					url: `test-runs/${id}/report-url`,
				}),
			}),
			// [getTraceURL]
			getTraceURL: builder.query<
				string,
				{ id: string; testId: string; fileName?: string }
			>({
				query: ({ id, testId, fileName }) => ({
					url: `test-runs/${id}/${testId}/trace-url${
						fileName ? `?fileName=${fileName}` : ''
					}`,
				}),
			}),
		}),
	});

export const {
	useGetTestRunsQuery,
	useUnhideReportMutation,
	useGetTestRunReportURLQuery,
	useLazyGetTraceURLQuery,
} = testRunsAPI;
