import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { TestSuiteRun, TestSuiteRunStatus } from '../../types';

export const useTestRunListItemStyles = (testRun: TestSuiteRun) => {
	const theme = useTheme();

	return useMemo(() => {
		const listItem = css({
			padding: '0',
		});

		const listItemButton = css({
			display: 'flex',
			justifyContent: 'space-between',
			padding: '16px',
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: '10px',
		});

		const contentWrapper = css({
			display: 'flex',
			flexDirection: 'column',
			gap: '12px',
		});

		const title = css({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			svg: {
				marginRight: theme.spacing(1),
				color:
					testRun.status === TestSuiteRunStatus.Passed
						? theme.colors.success.main
						: theme.colors.error.main,
			},
		});

		const resultsCount = css({
			display: 'flex',
			alignItems: 'center',
			svg: {
				height: '18px',
			},
		});

		const errorResultsCount = css(resultsCount, {
			color: theme.colors.error.main,
		});

		const successResultsCount = css(resultsCount, {
			color: theme.colors.success.main,
		});

		const resultsWrapper = css({
			display: 'flex',
			alignItems: 'center',
			gap: '8px',
		});

		const bottomBar = css({
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing(2),
			color: theme.colors.text.secondary,
			svg: {
				fill: theme.colors.text.secondary,
			},
		});

		const buttonIcon = css({
			color: theme.colors.text.primary,
		});

		const buttonIconText = css({
			textDecoration: 'none',
		});

		const bottomBarIndicator = css({
			display: 'flex',
			alignItems: 'center',
			svg: {
				height: '15px',
				minWidth: '24px',
			},
		});

		const unhideReportButton = css({
			marginLeft: 'auto', // Pushes the button to the right
			alignSelf: 'center', // Centers it vertically in the parent
		});

		return {
			buttonIcon,
			buttonIconText,
			bottomBar,
			bottomBarIndicator,
			listItem,
			listItemButton,
			contentWrapper,
			errorResultsCount,
			successResultsCount,
			resultsWrapper,
			title,
			unhideReportButton,
		};
	}, [theme, testRun?.status]);
};

export const useTestRunListItemBottomBarIndicatorStyles = () => {
	return useMemo(() => {
		const bottomBarIndicator = css({
			display: 'flex',
			alignItems: 'center',
			svg: {
				height: '15px',
			},
		});

		return {
			bottomBarIndicator,
		};
	}, []);
};

export const useTestRunListItemSkeletonStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const container = css({
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		});

		const row = css({
			display: 'flex',
			gap: theme.spacing(1),
			alignItems: 'center',
		});

		const fullWidth = css({
			width: '100%',
		});

		const column = css({
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		});

		const gap1 = css({
			gap: theme.spacing(1),
		});

		const gap2 = css({
			gap: theme.spacing(2),
		});

		const marginLeftMin = css({
			marginLeft: theme.spacing(0.5),
		});

		return {
			column,
			container,
			row,
			gap1,
			gap2,
			fullWidth,
			marginLeftMin,
		};
	}, [theme]);
};
