import api from '@src/api';

import { InboxDataResponse } from '@src/modules/collections/service';
import { collectionsApi } from '@src/modules/collections/service';
import {
	CreateUserStoryAPIArgs,
	GenerateUserStoryAPIArgs,
	GetUserStoriesAPIArgs,
	UpdateUserStoryAPIArgs,
	UserStory,
} from '@src/modules/user-stories/types';
import { buildGetUserStoriesQueryString } from '@src/modules/user-stories/api/helpers';
import {
	transformAPIUserStories,
	transformAPIUserStory,
	transformCreateUserStoryArgsToAPI,
	transformUpdateUserStoryArgsToAPI,
} from '@src/modules/user-stories/api/transformers';
import { APIGenerateUserStoryError } from '@src/modules/user-stories/api/types/errors';
import { AxiosResponse } from 'axios';

export const userStoriesAPI = api
	.enhanceEndpoints({
		addTagTypes: [
			'UserStories',
			'UserStory',
			'Collections',
			'InboxData',
			'InboxStories',
			'UserStoriesFiles',
			'AllUserStories',
		],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// [getUserStories]
			getUserStories: builder.query<UserStory[], GetUserStoriesAPIArgs>({
				query: (args) => ({
					url: `user-stories?${buildGetUserStoriesQueryString(args)}`,
				}),
				providesTags: (result, error, { collectionId }) => [
					{ type: 'UserStories', id: collectionId },
				],
				transformResponse: transformAPIUserStories,
			}),

			// [getAllUserStories]
			getAllUserStories: builder.query<UserStory[], void>({
				query: (args) => ({
					url: `user-stories`,
				}),
				providesTags: ['AllUserStories'],
				transformResponse: transformAPIUserStories,
			}),

			// [getUserStory]
			getUserStory: builder.query<UserStory, { id: string }>({
				query: ({ id }) => ({ url: `user-stories/${id}` }),
				providesTags: (result, error, { id }) => [{ type: 'UserStory', id }],
				transformResponse: transformAPIUserStory,
			}),
			// [createUserStory]
			createUserStory: builder.mutation<UserStory, CreateUserStoryAPIArgs>({
				query: (args) => {
					return {
						url: 'user-stories',
						method: 'POST',
						body: transformCreateUserStoryArgsToAPI(args),
						successToastMessage: 'Story created successfully',
					};
				},
				transformResponse: transformAPIUserStory,
				invalidatesTags: [
					'UserStories',
					'Collections',
					'InboxData',
					'UserStoriesFiles',
					'AllUserStories',
				],
			}),
			// [updateUserStory]
			updateUserStory: builder.mutation<void, UpdateUserStoryAPIArgs>({
				query: ({ id, update }) => {
					return {
						url: `user-stories/${id}`,
						method: 'PATCH',
						body: transformUpdateUserStoryArgsToAPI(update),
						successToastMessage: 'Story updated successfully',
					};
				},
				// TODO: can patch the specific story instead of pulling all stories again
				invalidatesTags: [
					'UserStories',
					'Collections',
					'UserStoriesFiles',
					'AllUserStories',
				],
			}),
			// [generateUserStory]
			generateUserStory: builder.mutation<boolean, GenerateUserStoryAPIArgs>({
				query: ({ id }) => {
					return {
						url: `user-stories/${id}/generate`,
						method: 'POST',
						successToastMessage: 'Test generation triggered successfully',
						makeErrorFromResponse: (
							response: AxiosResponse<APIGenerateUserStoryError>
						) => {
							const { data } = response;
							if (data?.type === 'too-many-active-generations') {
								return `You've reached the maximum number of active test generations (${data.limit}).`;
							}
							return 'An unknown error occurred.';
						},
					};
				},
				invalidatesTags: ['UserStories', 'AllUserStories'],
			}),
			// [generateUserStory]
			clearStoryFromInbox: builder.mutation<boolean, string>({
				query: (id) => {
					return {
						url: `user-stories/${id}/clearFromInbox`,
						method: 'POST',
					};
				},
				invalidatesTags: ['InboxStories'],
				async onQueryStarted(storyId, { dispatch, queryFulfilled }) {
					const patchResult = dispatch(
						collectionsApi.util.updateQueryData(
							'getUserInboxCollectionAndStories',
							undefined,
							(draft: InboxDataResponse) => {
								return {
									...draft,
									userStories: draft.userStories.filter(
										({ id }) => id !== storyId
									),
								};
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
			}),
			// [abortUserStoryGeneration]
			abortUserStoryGeneration: builder.mutation<
				UserStory,
				GenerateUserStoryAPIArgs
			>({
				query: ({ id }) => {
					return {
						url: `user-stories/${id}/abortGeneration`,
						method: 'POST',
						successToastMessage: 'Test generation aborted',
					};
				},
				invalidatesTags: ['UserStories', 'AllUserStories'],
			}),
			// [deleteUserStory]
			deleteUserStory: builder.mutation({
				query: (id) => {
					return {
						url: `user-stories/${id}`,
						method: 'DELETE',
						successToastMessage: 'Story deleted successfully',
					};
				},
				invalidatesTags: [
					'UserStories',
					'Collections',
					'InboxData',
					'AllUserStories',
				],
			}),
		}),
	});

export const {
	useGetUserStoriesQuery,
	useGetUserStoryQuery,
	useCreateUserStoryMutation,
	useUpdateUserStoryMutation,
	useGenerateUserStoryMutation,
	useAbortUserStoryGenerationMutation,
	useDeleteUserStoryMutation,
	useClearStoryFromInboxMutation,
	useGetAllUserStoriesQuery,
} = userStoriesAPI;
