import { useTestReport } from '@src/modules/test-runs/pages/test-report/hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { Button, Box } from '@mui/material';
import { useTestReportStyles } from './styles';
import { KeyboardBackspace } from '@mui/icons-material';

export const TestReport = () => {
	const { divRef, isLoading, onBack } = useTestReport();
	const styles = useTestReportStyles({ isLoading });

	return (
		<Box component="section" css={styles.section}>
			<Button variant="text" css={styles.backToTestRunsButton} onClick={onBack}>
				<KeyboardBackspace /> Back
			</Button>

			<If condition={isLoading}>
				<LoadingCircularProgress styles={styles.loading}>
					Loading report...
				</LoadingCircularProgress>
			</If>

			<div id="report-placeholder" css={styles.reportDiv} ref={divRef} />
		</Box>
	);
};
